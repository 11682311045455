import React, { useEffect, useState } from 'react'
import { parse as parseCSV } from "papaparse";
import onboardingMessages from '@/messages/onboarding'
import OrgOnboardingPage from './OrgOnboardingPage'
import { Box, Button, Center, HStack, Image, Text, VStack } from '@chakra-ui/react'
import downloadFile from '@/utils/downloadFile'
import FilePicker from '@/components/atoms/FilePicker'
import { TRosterItemPerson } from '@/types/Roster.types'
import iconsPng from '@/assets/img/png/icons';
import { useOrganizationOnboardingStore } from '@/store/useOrganizationOnboardingStore';

type Props = {
  onGoBack?: (file: File | null, preview: string | null, roster: TRosterItemPerson[] | null) => void
  onContinue?: (file: File | null, preview: string | null, roster: TRosterItemPerson[] | null) => void
}

const PreviewSection = ({ title, description, number, buttonLabel, onButtonPress, isButtonDisabled = false }: { title: string, description: string, number: string, buttonLabel: string, onButtonPress: () => void, isButtonDisabled?: boolean }) => {

  return <VStack>
    <HStack alignItems={'flex-start'}>
      <Center
        background={"secondary.500"}
        borderRadius={"full"}
        width={"30px"}
        height={"30px"}
      >
        <Text fontWeight={"bold"} color={"white"} lineHeight={'20px'} fontSize={"16px"}>
          {number}
        </Text>
      </Center>
      <VStack paddingX={'16px'}>
        <Text fontWeight={600} fontSize={"26px"} lineHeight={"32px"}>{title}</Text>
        <Text
          fontSize={"16px"}
          maxWidth={"400px"}
          textAlign={"center"}
          lineHeight={'20px'}
          marginTop={"16px"}
          color={"text.mediumBlue"}
          fontWeight={400}
        >{description}</Text>
      </VStack>
    </HStack>
    <Button marginTop={'54px'} variant={'continue'} onClick={onButtonPress} disabled={isButtonDisabled} isDisabled={isButtonDisabled}>
      {buttonLabel}
    </Button>
  </VStack>
}

const RosterTemplate = ({ onGoBack = () => { }, onContinue = () => { } }: Props) => {
  const csvFileRef = React.useRef<HTMLInputElement>(null)

  const { rosterFilePreview, rosterParsed } = useOrganizationOnboardingStore()

  // preview - file name
  const [preview, setPreview] = useState<string | null>(rosterFilePreview ?? null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [roster, setRoster] = useState<TRosterItemPerson[] | null>(rosterParsed ?? null)

  useEffect(() => {
    if (selectedFile) {
      parseCSV(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results: { data: TRosterItemPerson[] }) => {
          setRoster(results.data as TRosterItemPerson[]);
        },
      })

      setPreview(selectedFile?.name)
    }
  }, [selectedFile])

  const triggerFilePicker = () => {
    if (csvFileRef.current) {
      csvFileRef.current.click();
    }
  }

  return (
    <OrgOnboardingPage
      title={onboardingMessages.createYourRosterTitle}
      subTitle={onboardingMessages.createYourRosterSubtitle}
      description={onboardingMessages.createYourRosterDescription}
      onGoBack={() => {
        onGoBack(selectedFile, preview, roster)
      }}
      onSubmit={() => {
        onContinue(selectedFile, preview, roster)
      }}
    >
      <VStack spacing={'80px'} marginTop={'40px'}>
        <PreviewSection
          title={onboardingMessages.downloadOurRosterTemplate}
          description={onboardingMessages.downloadRosterTemplateDescription}
          number={"1"}
          buttonLabel={onboardingMessages.downloadRosterTemplate}
          onButtonPress={() => {
            downloadFile('/files/roster-template.csv')
          }}
        />
        <VStack>
          <PreviewSection
            title={onboardingMessages.uploadYourCompletedRoster}
            description={onboardingMessages.uploadYourRosterDescription}
            number={"2"}
            buttonLabel={onboardingMessages.uploadCSV}
            onButtonPress={triggerFilePicker}
            isButtonDisabled={preview !== null && preview !== ''}
          />

          {preview && (
            <HStack
              width={"295px"}
              marginTop={"20px"}
              borderRadius={"8px"}
              background={"white"}
              padding={"18px"}
              justify={"space-between"}
            >
              <Text color={'text.darkBlue'} fontSize={'14px'} fontWeight={'500'} noOfLines={1} marginRight={'18px'}>{preview}</Text>
              <Box
                onClick={() => {
                  setSelectedFile(null);
                  if (csvFileRef.current) csvFileRef.current.value = "";
                  setPreview(null)
                }}
                cursor={"pointer"}
              >
                <Image
                  alt="delete-csv-x"
                  src={iconsPng.redX}
                  width={25}
                  height={25}
                />
              </Box>
            </HStack>
          )}
        </VStack>
      </VStack>
      <FilePicker
        inputRef={csvFileRef}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setPreview={setPreview}
        accept={'.csv'}
      />
    </OrgOnboardingPage>
  )
}

export default RosterTemplate