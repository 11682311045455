import { THealthDomain } from "@/types/Health.types";

export const getScoresPerDomainForFormValues = (formValues: Record<string, number>, formDomains: Record<string, THealthDomain>): Record<THealthDomain, number> => {
  const scoresPerDomain: Record<THealthDomain, number> = {
    physical: 0,
    mental: 0,
    social: 0,
    vocational: 0,
    spiritual: 0,
    purpose: 0,
    fun: 0,
  };

  Object.entries(formValues).forEach(([key, value]) => {
    const domain = formDomains[key];
    scoresPerDomain[domain] += value;
  });

  const result: Record<string, number> = {}

  Object.keys(scoresPerDomain).forEach((healthDomain) => {
    const numberOfQuestionsForDomain = Object.values(formDomains).filter((value) => value === healthDomain).length;
    
    const domainScoresSum = scoresPerDomain[healthDomain as THealthDomain] / 10;
    const score = domainScoresSum / numberOfQuestionsForDomain;

    result[healthDomain] = score;
  })

  return result;
}