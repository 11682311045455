const loraTitleCommonStyles = {
  fontFamily: 'Lora',
  fontStyle: 'oblique',
  fontSize: '36px',
  color: 'text.darkBlue',
  lineHeight: '48px',
  textAlign: 'center',
};

const urbanistCommonStyles = {
  fontFamily: 'Urbanist',
  fontSize: '14px',
  lineHeight: '16.8px',
};

export default {
  Text: {
    defaultProps: {
      size: 'md',
      fontFamily: 'Urbanist',
      color: 'text.mediumGray',
    },
    baseStyle: {
      lineHeight: '26px',
      fontWeight: '400',
    },
    variants: {
      lora: {
        fontFamily: 'Lora',
      },
      loraTitle: {
        ...loraTitleCommonStyles,
        fontSize: '32px',
        lineHeight: '40px',
        fontWeight: '500',
      },
      loraSmallTitle: {
        ...loraTitleCommonStyles,
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: '400',
      },
      loraItalicTitle: {
        ...loraTitleCommonStyles,
        fontWeight: '400',
      },
      loraMediumItalicTitle: {
        ...loraTitleCommonStyles,
        fontWeight: '500',
      },
      loraSemiBoldItalicTitle: {
        ...loraTitleCommonStyles,
        fontWeight: '600',
      },
      loraBoldItalicTitle: {
        ...loraTitleCommonStyles,
        fontWeight: '700',
      },
      urbanistRegular: {
        ...urbanistCommonStyles,
        fontWeight: '400',
      },
      urbanistSmall: {
        ...urbanistCommonStyles,
        fontSize: '12px',
        lineHeight: '14px',
      },
      urbanistMedium: {
        ...urbanistCommonStyles,
        fontWeight: '500',
      },
      urbanistSemiBold: {
        ...urbanistCommonStyles,
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
      },
      urbanistSemiBoldTitle: {
        ...urbanistCommonStyles,
        fontWeight: '600',
        fontSize: '26px',
        lineHeight: '31px',
      },
      urbanistBold: {
        ...urbanistCommonStyles,
        fontWeight: '700',
      },
      urbanistExtraBold: {
        ...urbanistCommonStyles,
        fontWeight: '800',
      },
      urbanistExtraBoldSmall: {
        ...urbanistCommonStyles,
        fontWeight: '800',
        fontSize: '10px',
        lineHeight: '12px',
      },
      urbanistTitleLarge: {
        fontSize: '36px',
        lineHeight: '43px',
        textAlign: 'center',
        color: 'text.veryLightBlue',
        opacity: '0.7',
      },
      error: {
        ...urbanistCommonStyles,
        color: 'extra.red',
      },
      assessmentQuestionTitle: {
        ...urbanistCommonStyles,
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 500,
        textAlign: 'center',
        color: '#004179',
        marginTop: '40px',
      },
      assessmentQuestionText: {
        fontFamily: 'lora',
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 400,
        textAlign: 'center',
        color: '#004179',
        marginBottom: '20px',
        fontStyle: 'italic',
        marginTop: '20px',
      },
    },
  },
};
