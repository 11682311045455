/* eslint-disable @typescript-eslint/no-explicit-any */
import { THealthDomain } from '@/types/Health.types';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

type TScreenerState = {
  screenerId: string;
  slug: string;
  form: Record<string, any>;
  step: number;
  isCompleted: boolean;
  registerField: (key: string, healthDomain: THealthDomain) => void;
  setScreenerId: (screenerId: string, slug: string) => void;
  // demographics
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
};

const initialState = {
  screenerId: '',
  slug: '',
  form: {},
  step: 0,
  isCompleted: false,
  demographics: null
};

/**
 * NOTE: Example of a store that requires persistence and subscription.
 * State will persis for user even after page refresh.
 * Use this as a reference when creating a new store.
 */
export const useScreenerStore = create<TScreenerState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        ...initialState,
        setScreenerId: (screenerId: string, slug: string) => {
          set((state) => ({
            ...state,
            screenerId,
            slug,
          }));
        },
        registerField: (key: string, healthDomain: THealthDomain) => {
          set((state) => ({
            ...state,
            form: {
              ...state.form,
              [key]: healthDomain,
            },
          }));
        },
        nextStep: () => {
          set((state) => {
            if (!state.isCompleted) {
              return { ...state, step: state.step + 1 };
            }
            return state;
          });
        },
        prevStep: () =>
          set((state) => {
            if (state.step === 0) {
              return state;
            }
            return { ...state, step: state.step - 1 };
          }),
        reset: () => {
          set((state) => ({
            ...state,
            ...initialState,
          }));
        },
      }),
      { name: 'screenerStore' },
    ),
  ),
);
