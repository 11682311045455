import SvgIcon from '@/components/atoms/SvgIcon';
import queryKeys from '@/constants/queryKeys';
import {
  inviteUsersToOrganizationPost,
  organizationMembersForAdminGet,
  removeMemberFromOrganization,
} from '@/services/api/requests/organization';
import colors from '@/theme/colors';
import { TUser, TUserContactInfo } from '@/types/User.types';
import { Box, Center, Flex, HStack, Image, Input, Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useToast from '@/hooks/useToast';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppStore } from '@/store/useAppStore';

import CustomButton from '@/components/atoms/CustomButton';
import { FORM_FIELD_WIDTH, MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import Loader from '@/components/atoms/Loader';
import ModalContainer from '@/components/molecules/ModalContainer';
import OrganizationRosterDeleteItemModal from './OrganizationRosterDeleteItemModal';
import DeleteIcon from '@/components/atoms/DeleteIcon';
import { TRosterItemWithIdAndRole } from '@/types/Roster.types';
import svgIcons from '@/assets/svg/icons';
import iconsPng from '@/assets/img/png/icons';
import tableTheme from '@/theme/components/table';
import { TABLE_COLUMNS_ORG_ROSTER_VIEW } from '@/utils/tableConfigs';
import { mixpanelEvents } from '@/constants/mixpanel';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import roles from '@/constants/roles';
import { EMAIL_REGEX } from '@/constants/stringVars';

const addMemberSchema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .matches(/^[a-zA-Z ]+$/, 'Only alphabetic characters allowed')
      .required('First Name is required.'),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z ]+$/, 'Only alphabetic characters allowed')
      .required('Last Name is required.'),
    phone: yup.string().required('Phone is required.'),
    email: yup.string().matches(EMAIL_REGEX, 'Wrong email format').required('Email is required.'),
  })
  .required();

const mapBeUserToTableUser = (users: TUser[]): TRosterItemWithIdAndRole[] =>
  users.map((user) => ({
    'First Name': user.firstName ?? user.user_metadata.firstName,
    'Last Name': user.lastName ?? user.user_metadata.lastName,
    Phone: user.user_metadata.phone ?? '/',
    Email: user.email,
    Invitation: user.email_verified ? (
      'Accepted'
    ) : (
      <HStack>
        <Text color={'background.blueGray'}>Pending</Text>
        <SvgIcon color={colors.background.blueGray} iconPath={svgIcons.reload} style={{ marginTop: '8px' }} />
      </HStack>
    ),
    id: user.user_id,
    isAdmin: user.roles?.includes(roles.orgAdmin),
  }));

const OrgMembersRoster = () => {
  const theme = useTheme(tableTheme);
  const toast = useToast();
  const { trackEvent } = useTelemetry();

  const { organization } = useAppStore();

  const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false);

  const [itemToDeleteEmail, setItemToDeleteEmail] = useState('');
  const [itemToDeleteId, setItemToDeleteId] = useState('');

  const onClickDelete = useCallback((item: TRosterItemWithIdAndRole) => {
    setDeleteConfirmationModalVisible(true);
    setItemToDeleteId(item.id);
    setItemToDeleteEmail(item.Email);
  }, []);

  const tableColumns = useMemo(() => {
    const columns: {
      label: string | JSX.Element;
      renderCell: (item: TRosterItemWithIdAndRole) => string | JSX.Element;
    }[] = TABLE_COLUMNS_ORG_ROSTER_VIEW;

    if (columns.length === 5) {
      columns.push({
        label: <Flex maxWidth={'100px'}>DELETE</Flex>,
        renderCell: () => '',
      });
    }
    return columns;
  }, [onClickDelete]);

  const {
    isLoading: isOrgMembersLoading,
    isRefetching: isOrgMembersRefetchLoading,
    data: orgMembers,
    refetch: refetchOrgMembers,
  } = useQuery({
    queryKey: [queryKeys.organization.members],
    queryFn: organizationMembersForAdminGet,
    select: mapBeUserToTableUser,
  });

  const [isWaitLoading, setIsWaitLoading] = useState(false);

  const isLoading = isOrgMembersLoading || isOrgMembersRefetchLoading || isWaitLoading;

  const {
    register,
    getValues,
    reset: resetUserForm,
    formState,
  } = useForm<TUserContactInfo>({
    resolver: yupResolver(addMemberSchema),
    mode: 'all',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '0123456789', // TODO: Handle phone number as optional
    },
  });
  const { isValid, errors } = formState;

  const [isUserInviteLoading, setIsUserInviteLoading] = useState(false);

  const [rawData, setRawData] = useState([] as TRosterItemWithIdAndRole[]);

  useEffect(() => {
    if (orgMembers) {
      setRawData(orgMembers);
    }
  }, [orgMembers]);

  const data = useMemo(() => ({ nodes: rawData ?? [] }), [rawData]);

  const reFetch = useCallback(() => {
    // TODO: see how to improve this... remove the wishful 2s wait and waitLoading...
    setTimeout(() => setIsWaitLoading(true), 700);
    setTimeout(() => {
      refetchOrgMembers();
      setIsWaitLoading(false);
    }, 2000);
  }, [refetchOrgMembers]);

  const deleteMember = useCallback(
    (memberId: string) => {
      setTimeout(() => setIsWaitLoading(true), 700);
      removeMemberFromOrganization(memberId).then(() => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'User deletion successful!',
        });

        trackEvent(mixpanelEvents.REMOVE_ORG_MEMBER);

        reFetch();
      });
    },
    [toast, reFetch, trackEvent],
  );

  const addMember = useCallback(async () => {
    //TODO: Handle phone number as optional
    const usersToInvite: TUserContactInfo[] = [{ ...getValues(), phone: '' }];

    try {
      setIsUserInviteLoading(true);
      await inviteUsersToOrganizationPost(usersToInvite);

      toast({
        status: 'success',
        title: 'Success',
        description: 'User invitation successful!',
      });

      trackEvent(mixpanelEvents.ADD_ORG_MEMBER);

      reFetch();

      resetUserForm();

      setAddMemberModalVisible(false);
    } catch (error: unknown) {
      console.error('error', error);
      toast({
        status: 'error',
        title: 'Something went wrong',
        description: 'Please try again.',
      });
    } finally {
      setIsUserInviteLoading(false);
    }
  }, [getValues, resetUserForm, toast, trackEvent, reFetch]);

  return (
    <Center marginTop={'30px'}>
      <VStack width={MAX_CONTAINER_WIDTH} gap={0}>
        <HStack width={'100%'} justifyContent={'space-between'} alignItems={'stretch'}>
          {isLoading ? (
            <Loader size={'md'} marginTop={0} width="10%" />
          ) : (
            <Text variant={'loraTitle'}>{organization?.name}&apos;s Roster</Text>
          )}

          <CustomButton
            width={'150px'}
            height={'55px'}
            onClick={() => setAddMemberModalVisible(true)}
            backgroundColor="primary.500"
            labelColor="extra.white"
            label="Add Member"
          />
        </HStack>

        {isLoading ? (
          <VStack paddingBottom={'20vh'}>
            <Loader />
          </VStack>
        ) : (
          <VStack>
            <Box marginTop={'60px'} marginBottom={'80px'}>
              {data.nodes.length > 0 ? (
                <Table data={data} theme={theme}>
                  { }
                  {(tableDataItems: TRosterItemWithIdAndRole[]) => (
                    <>
                      <Header>
                        <HeaderRow>
                          {tableColumns.map((column) => {
                            if (column.label === 'INVITATION') return null; // TODO: bring back invitation at some point
                            return <HeaderCell key={column.label.toString()}>{column.label}</HeaderCell>;
                          })}
                        </HeaderRow>
                      </Header>

                      <Body>
                        {tableDataItems.map((item) => {
                          return (
                            <Row key={item.id} item={item}>
                              <Cell>{item['First Name']}</Cell>
                              <Cell>{item['Last Name']}</Cell>
                              <Cell>{item.Phone}</Cell>
                              <Cell>{item.Email}</Cell>
                              {/* <Cell>{item.Invitation}</Cell> // TODO: bring back invitation at some point */}
                              {/* Add in the delete button for regular users or "Admin" text for admins */}
                              <Cell>
                                {!item.isAdmin ? (
                                  <DeleteIcon onClick={() => onClickDelete(item)} centered={false} />
                                ) : (
                                  <Text variant={'urbanistBold'}>Admin</Text>
                                )}
                              </Cell>
                            </Row>
                          );
                        })}
                      </Body>
                    </>
                  )}
                </Table>
              ) : (
                <Text variant={'loraSmallTitle'} color={'text.darkBlue'}>
                  No Members Found
                </Text>
              )}
            </Box>
          </VStack>
        )}

        {/* Add Member modal */}
        <ModalContainer
          isOpen={addMemberModalVisible}
          onClose={() => setAddMemberModalVisible(false)}
          isBlur={false}
          width={'40%'}
          height={'20vh'}
          backgroundColor={'background.lightBlue'}
          closeButtonColor={'black'}
        >
          <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'}>
            Add Member to your Organization
          </Text>
          <Center marginTop={'20px'}>
            <Image alt="add-member" src={iconsPng.addMember} width={120} height={120} />
          </Center>
          <Center>
            <VStack marginTop={'40px'} width={FORM_FIELD_WIDTH} gap={5}>
              <Input
                {...register('firstName', {
                  required: true,
                })}
                className="custom-input"
                placeholder={'First Name'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
              />
              {errors.firstName && <Text variant={'error'}>{errors.firstName.message}</Text>}
              <Input
                {...register('lastName', {
                  required: true,
                })}
                className="custom-input"
                placeholder={'Last Name'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
              />
              {errors.lastName && <Text variant={'error'}>{errors.lastName.message}</Text>}
              {/* <Input
                {...register('phone', {
                  required: true,
                })}
                className="custom-input"
                placeholder={'Phone Number'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
              />
              {errors.phone && <Text variant={'error'}>{errors.phone.message}</Text>}
              */}
              <Input
                {...register('email', { required: true })}
                className="custom-input"
                placeholder={'Email Address'}
                type="email"
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
              />
              {errors.email && <Text variant={'error'}>{errors.email.message}</Text>}
              <CustomButton
                style={{ marginTop: '20px' }}
                backgroundColor="secondary.500"
                label="Invite Member"
                width={FORM_FIELD_WIDTH}
                labelColor="white"
                onClick={addMember}
                disabled={!isValid}
                isLoading={isUserInviteLoading}
              />
            </VStack>
          </Center>
        </ModalContainer>

        <OrganizationRosterDeleteItemModal
          deleteConfirmationModalVisible={deleteConfirmationModalVisible}
          setDeleteConfirmationModalVisible={setDeleteConfirmationModalVisible}
          deleteItem={deleteMember}
          itemToDeleteId={itemToDeleteId}
          itemToDeleteEmail={itemToDeleteEmail}
        />
      </VStack>
    </Center>
  );
};

export default OrgMembersRoster;
