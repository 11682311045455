import { format } from 'date-fns';

import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import colors from '@/theme/colors';
import { OPTION_OTHER } from '@/utils/demographicDataMappers';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import UserProfileBox from './UserProfileBox';
import UserProfileBoxInfoPiece from './UserProfileBoxInfoPiece';

const ITEMS_GAP_PX = '24px';

interface UserDemographicsCardProps {
  dateOfBirth: string;
  heightFeet: number;
  heightInches: number;
  weight: number;
  sex: string;
  sexOther?: string;
  occupation: string;
  occupationOther?: string;
  ethnicity: string;
  ethnicityOther?: string;
  relationshipStatus: string;
  relationshipStatusOther?: string;
  education: string;
  educationOther?: string;
  onClickEdit: () => void;
  hideEditButton?: boolean;
}

const UserDemographicsBox = ({
  dateOfBirth,
  heightInches,
  heightFeet,
  sex,
  sexOther,
  weight,
  occupation,
  occupationOther,
  ethnicity,
  ethnicityOther,
  relationshipStatus,
  relationshipStatusOther,
  education,
  educationOther,
  onClickEdit,
  hideEditButton = false
}: UserDemographicsCardProps) => {
  return (
    <UserProfileBox style={{ height: '360px' }}>
      <HStack justifyContent={'space-between'}>
        <Text variant={'urbanistSemiBoldTitle'} marginBottom={'10px'}>
          Demographics
        </Text>
        {!hideEditButton && <CustomButton
          onClick={onClickEdit}
          hoverBackgroundColor={'primary.100'}
          label="Edit"
          labelColor="text.darkBlue"
          style={{
            padding: 0,
            borderColor: colors.text.darkBlue,
            borderWidth: '2px',
          }}
          width={'110px'}
          height={'55px'}
          iconBeforeLabelJSX={
            <Image
              alt="edit-icon"
              src={iconsPng.pen}
              width={'12px'}
              height={'12px'}
              objectFit="cover"
              style={{ marginRight: '10px' }}
            />
          }
        />}
      </HStack>
      <HStack gap={'50px'} marginTop={'20px'}>
        <VStack alignItems={'start'} gap={ITEMS_GAP_PX}>
          <UserProfileBoxInfoPiece
            title={'DATE OF BIRTH'}
            text={dateOfBirth ? format(new Date(dateOfBirth), 'MM/dd/yyyy') : ''}
          />
          <UserProfileBoxInfoPiece title={'HEIGHT'} text={`${heightFeet}’ ${heightInches}”`} />
          <UserProfileBoxInfoPiece title={'SEX'} text={sex === OPTION_OTHER ? (sexOther ?? '') : sex} />
          <UserProfileBoxInfoPiece title={'WEIGHT'} text={`${weight} lbs`} />
        </VStack>
        <VStack alignItems={'start'} gap={ITEMS_GAP_PX}>
          <UserProfileBoxInfoPiece
            title={'OCCUPATION'}
            text={occupation === OPTION_OTHER ? (occupationOther ?? '') : occupation}
          />
          <UserProfileBoxInfoPiece
            title={'ETHNICITY'}
            text={ethnicity === OPTION_OTHER ? (ethnicityOther ?? '') : ethnicity}
          />
          <UserProfileBoxInfoPiece
            title={'RELATIONSHIP STATUS'}
            text={relationshipStatus === OPTION_OTHER ? (relationshipStatusOther ?? '') : relationshipStatus}
          />
          <UserProfileBoxInfoPiece
            title={'HIGHEST LEVEL OF EDUCATION'}
            text={education === OPTION_OTHER ? (educationOther ?? '') : education}
          />
        </VStack>
      </HStack>
    </UserProfileBox>
  );
};

export default UserDemographicsBox;
