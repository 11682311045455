import React, { useEffect } from 'react'
import { Controller, useFormContext } from "react-hook-form";
import SliderExtended from '.';
import { useScreenerStore } from '@/store/useScreenerStore';
import { THealthDomain } from '@/types/Health.types';

type Props = {
  question: string;
  name: string;
  labelStart?: string;
  labelEnd?: string;
  healthDomain: THealthDomain;
  isAnswered?: boolean;
  onChangeExternal?: (_: number) => void;
}

const ControlledSliderExtended = ({
  labelStart = "Less",
  labelEnd = "More",
  name,
  healthDomain,
}: Props) => {
  const { registerField } = useScreenerStore()

  useEffect(() => {
    registerField(name, healthDomain)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  

  const { control } = useFormContext();

  return (
    <Controller
      key={name}
      control={control}
      name={name}
      defaultValue={50}
      render={({ field: { onChange, value } }) => (
        <SliderExtended
          value={value}
          setValue={(val) => {
            onChange(val);
          }}
          labelStart={labelStart}
          labelEnd={labelEnd}
        />
      )}
    
    />
  )
}

export default ControlledSliderExtended