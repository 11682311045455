type ColorObject = {
  [key: number]: string;
};

const sliderColorPalette: ColorObject = {
  0: "#EC4D84",
  1: "#ec5080",
  2: "#ed547d",
  3: "#ed577a",
  4: "#ed5b76",
  5: "#ee5f73",
  6: "#ee6370",
  7: "#ee666c",
  8: "#ef6a69",
  9: "#ef6d65",
  10: "#ef6f61",
  11: "#ec7960",
  12: "#ea835f",
  13: "#e88d5d",
  14: "#e6985c",
  15: "#e3a25b",
  16: "#e1ac5a",
  17: "#dfb659",
  18: "#ddc058",
  19: "#dbcb56",
  20: "#e09352",
  21: "#da9d57",
  22: "#d4a75c",
  23: "#cdb262",
  24: "#c7bc67",
  25: "#c1c66c",
  26: "#bbc171",
  27: "#b5cc77",
  28: "#afdc7c",
  29: "#aad781",
  30: "#c8b161",
  31: "#c0b968",
  32: "#b9c06e",
  33: "#b1c775",
  34: "#aacf7c",
  35: "#a2d683",
  36: "#9bde89",
  37: "#93e590",
  38: "#8cea97",
  39: "#84f09d",
  40: "#a2cf8f",
  41: "#9bcf94",
  42: "#95d098",
  43: "#8fd09d",
  44: "#89d1a1",
  45: "#83d1a5",
  46: "#7dd2aa",
  47: "#77d2ae",
  48: "#71d3b3",
  49: "#6bd3b7",
  50: "#81d8a9",
  51: "#7ed7a5",
  52: "#7ad6a0",
  53: "#76d59c",
  54: "#72d497",
  55: "#6fd392",
  56: "#6bd28e",
  57: "#67d189",
  58: "#63d084",
  59: "#5fcf80",
  60: "#68d093",
  61: "#64ce8d",
  62: "#60cc87",
  63: "#5cc981",
  64: "#58c77b",
  65: "#54c575",
  66: "#50c370",
  67: "#4cc16a",
  68: "#48bf64",
  69: "#44bd5e",
  70: "#4fc77c",
  71: "#4ac474",
  72: "#44c06c",
  73: "#3ebc64",
  74: "#38b85c",
  75: "#33b454",
  76: "#2db04c",
  77: "#27ab44",
  78: "#21a73c",
  79: "#1ba334",
  80: "#33be63",
  81: "#2fb95b",
  82: "#2ab353",
  83: "#26ae4b",
  84: "#21a943",
  85: "#1da43b",
  86: "#189f33",
  87: "#14992b",
  88: "#109423",
  89: "#0b8f1b",
  90: "#16a355",
  91: "#14a454",
  92: "#12a554",
  93: "#0fa653",
  94: "#0da752",
  95: "#0aa852",
  96: "#08a951",
  97: "#06aa51",
  98: "#03ab50",
  99: "#01ac50",
  100: "#00b547",
};

export default sliderColorPalette;