import React from 'react'
import {
  Box,
  Button,
  Center,
  Stack,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  Image,
  HStack,
  Checkbox,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import HeroBackgroundPage from '@/components/templates/HeroBackgroundPage';
import onboardingMessages from '@/messages/onboarding';
import MyOmniaInfoIllustrations from '@/components/templates/MyOmniaInfoIllustrations';
import iconsPng from '@/assets/img/png/icons';
import EulaLinkedText from '@/components/molecules/EulaLinkedText';
import useUserInfoAndEulaAccept from '@/hooks/onboarding/useUserInfoAndEulaAccept';
import getUserAgentInfo from '@/utils/getUserAgentInfo';
import { useAppStore } from '@/store/useAppStore';
import { TUserInfoFormData } from './types';
import useToast from '@/hooks/useToast';

type Props = {
  onSubmitSuccess?: () => void
}

const schema = yup.object({
  email: yup.string().email('Please enter valid email address.').required('This field is required.'),
  firstName: yup.string().required('This field is required.'),
  lastName: yup.string().required('This field is required.'),
  phone: yup.string().required('This field is required.'),
  eula: yup.boolean().oneOf([true], 'Please accept the EULA')
}).required();

const ContactInfoForm = ({ onSubmitSuccess = () => { } }: Props) => {
  const toast = useToast()

  const { user, isEulaAccepted } = useAppStore()

  const [eulaAcceptedAt, setEulaAcceptedAt] = React.useState<number | null>(null)

  const { register, formState: { isValid, errors }, handleSubmit } = useForm<TUserInfoFormData>({
    resolver: yupResolver(schema), mode: 'all', defaultValues: {
      email: user?.email || '',
      firstName: user?.user_metadata.firstName || '',
      lastName: user?.user_metadata.lastName || '',
      phone: user?.user_metadata.phone || '',
      // TODO: Check if user has already accepted EULA
      // in case of super admin he could accept it previously
      eula: isEulaAccepted || false
    }
  });

  const { isPending, mutate } = useUserInfoAndEulaAccept(() => {
    onSubmitSuccess()
  })

  const onEulaCheckboxCapture = () => {
    const nowUtcTimestamp = new Date().getTime()
    setEulaAcceptedAt(nowUtcTimestamp)
  }

  const onFormSubmit = (data: TUserInfoFormData) => {
    const userAgentString = navigator.userAgent
    const { operating_system, browser } = getUserAgentInfo(userAgentString);

    // TODO: Check if user has already accepted EULA
    // in case of super admin he could accept it previously
    if(eulaAcceptedAt === null && !isEulaAccepted){
      toast({
        title: 'EULA Acceptance Required',
        description: onboardingMessages.eulaNotAcceptedProperly,
        status: 'error',
        isClosable: true,
      })
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { eula, ...restContactInfo } = data;

    if(eulaAcceptedAt === null){
      mutate({
        userInfo: { ...restContactInfo }
      })
      return
    }

    mutate({
      userInfo: { ...restContactInfo }, eulaAcceptanceInfo: {
      operatingSystem: operating_system,
      browser,
      timestamp: eulaAcceptedAt,
    } })
  }

  return (
    <HeroBackgroundPage title={onboardingMessages.userOnboardingTitle}
      heroChildren={<Stack alignItems={'center'}>
        <Text variant={"urbanistSemiBold"} color={'text.veryLightBlue'} opacity={0.7} position={"relative"} marginTop={"20px"} maxWidth={'400px'}>
          {onboardingMessages.userOnboardingDescription}
        </Text>
        <Box maxW={{ md: '295px' }} minW={{ md: '295px' }} marginTop={'30px'}>
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onFormSubmit)}>
            <Stack>
              <VStack
                gap={0}
                backgroundColor={"white"}
                borderRadius={"8px"}
                paddingX={"16px"}
                cursor={"not-allowed"}
                marginBottom={'10px'}
              >
                <Text
                  color={"text.mediumGray"}
                  fontWeight={800}
                  lineHeight={'12px'}
                  fontSize={"10px"}
                  paddingTop={"12px"}
                  width={"100%"}
                  textAlign={"start"}
                >
                  {onboardingMessages.yourEmailAddress.toUpperCase()}
                </Text>
                <InputGroup>
                  <Input
                    {...register("email", { required: true })}
                    disabled={true} // disabling this always; because we are only coming to this page with an existing email
                    _disabled={{ opacity: 1, cursor: "not-allowed" }}
                    maxHeight={"22px"}
                    lineHeight={'18px'}
                    borderWidth={"0"}
                    fontSize={"14px"}
                    padding={"0px"}
                    marginBottom={"8px"}
                    fontWeight={600}
                    color={"text.mediumGray"}
                  />
                  <InputRightElement
                    width="2rem"
                    height={"100%"}
                    marginTop={"-12px"}
                  >
                    <Image
                      alt="lock"
                      src={iconsPng.lock}
                      width={25}
                      height={25}
                    />
                  </InputRightElement>
                </InputGroup>
              </VStack>
              <Stack>
                <Input
                  {...register("firstName", { required: true })}
                  className="custom-input"
                  placeholder={"First Name"}
                  _placeholder={{ fontWeight: "600", color: "text.mediumGray" }}
                />
                <Text variant={'error'} textAlign={'left'}>{errors.firstName?.message ?? ''}</Text>
              </Stack>
              <Stack>
                <Input
                  {...register("lastName", { required: true })}
                  className="custom-input"
                  placeholder={"Last Name"}
                  _placeholder={{ fontWeight: "600", color: "text.mediumGray" }}
                />
                <Text variant={'error'} textAlign={'left'}>{errors.lastName?.message ?? ''}</Text>
              </Stack>
              <Stack>
                <Input
                  {...register("phone", { required: true })}
                  className="custom-input"
                  placeholder={"Phone Number"}
                  _placeholder={{ fontWeight: "600", color: "text.mediumGray" }}
                />
                <Text variant={'error'} textAlign={'left'}>{errors.phone?.message ?? ''}</Text>
              </Stack>
              {!isEulaAccepted && <VStack paddingX={'4px'}>
                <HStack >
                  <HStack width={'24px'}>
                    <Checkbox
                      {...register("eula", { required: true, onChange: onEulaCheckboxCapture, })}
                      colorScheme="white"
                      backgroundColor={'white'}
                      borderColor={'white'}
                      borderRadius={'4px'}
                      width={'22px'}
                      height={'22px'}
                      iconColor='text.mediumGray'
                      justifyContent={'center'}
                      alignItems={'center'}
                    />
                  </HStack>
                  <EulaLinkedText />
                </HStack>
                <Text variant={'error'} textAlign={'left'}>{errors.eula?.message ?? ''}</Text>
              </VStack>}
              <Button
                type="submit"
                variant="formSubmit"
                width="full"
                isLoading={isPending}
                isDisabled={!isValid}
                disabled={!isValid}
                marginTop={'10px'}
              >
                {onboardingMessages.proceed}
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>}
    >
      <Center marginY={'100px'}>
        <MyOmniaInfoIllustrations variant="how_can_my_omnia_help" />
      </Center>
    </HeroBackgroundPage>
  )
}

export default ContactInfoForm