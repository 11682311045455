import { useEffect } from 'react';
import type { CSSProperties } from 'react';

import * as d3 from 'd3';
import colors from '@/theme/colors';
import { Center, Flex, Text } from '@chakra-ui/react';

export type CircleFillOutWithTextProps = {
  svgId: string;
  circleColor?: string;
  circleDiameter?: number;
  percentageFilled: number;
  percentageTextStyle?: CSSProperties;
  thickness?: number;
  centeredText?: string;
  backgroundTransparent?: boolean;
  backgroundColor?: string;
};

const CircleFillOutWithText = ({
  svgId,
  circleColor,
  circleDiameter = 64,
  percentageFilled,
  percentageTextStyle = {
    color: 'text.darkBlue',
    fontWeight: 700,
    fontSize: 11,
  },
  thickness = 5,
  centeredText,
  backgroundTransparent = false,
  backgroundColor = 'white',
}: CircleFillOutWithTextProps) => {
  const outerRadius = circleDiameter / 2;

  const circleArc = d3
    .arc<unknown>()
    .innerRadius(outerRadius - thickness)
    .outerRadius(outerRadius);

  const arcTween = (transition: any, newStartAngle: any, newFinishAngle: any) => {
    transition.attrTween('d', function (d: any) {
      const interpolateStart = d3.interpolate(d.startAngle, newStartAngle);
      return function (t: any) {
        d.endAngle = newFinishAngle;
        d.startAngle = interpolateStart(t);
        return circleArc(d);
      };
    });
  };

  useEffect(() => {
    // minus sign makes the circle fill counter-clockwise (as design requires)
    // Math.PI * 2 is the full circle; so we just multiply it with filled percentage value
    const ANGLE = -Math.PI * 2 * (percentageFilled / 100);
    const selectedSvg = d3
      .select(`#${svgId}`)
      .append('g')
      .attr('transform', `translate(${outerRadius}, ${outerRadius})`);

    const svgForeground = selectedSvg
      .append('path')
      .datum({
        endAngle: 0,
        startAngle: 0,
      })
      .style('fill', circleColor || colors.secondary[500])
      .attr('d', circleArc);

    setTimeout(() => {
      svgForeground.transition().duration(1750).call(arcTween, ANGLE, 0.01); // 0.01 needed, because 0 makes it not animate...
    }, 300);
  }, [svgId, outerRadius, circleColor, percentageFilled]);

  return (
    <Flex
      position={'relative'}
      background={backgroundTransparent ? 'transparent' : backgroundColor}
      width={`${circleDiameter}px`}
      height={`${circleDiameter}px`}
      borderRadius={'50%'}
      boxShadow={'0px 10px 20px 0px #0E006224'}
    >
      <Center position={'absolute'} flexDir={'column'} width={'100%'} height={'100%'}>
        <Text style={percentageTextStyle}>{percentageFilled}</Text>
        <Text color={colors.extra.white} paddingX={'25px'} textAlign={'center'} fontSize={14} fontWeight={700}>
          {centeredText}
        </Text>
      </Center>
      <svg id={svgId}></svg>
    </Flex>
  );
};

export default CircleFillOutWithText;
