import { useMemo } from 'react';

import { Center, Flex, Image } from '@chakra-ui/react';
import { THealthDomainScores } from '@/types/Health.types';
import DrawnCirclesBackground from '@/components/atoms/DrawnCirclesBackground';
import ColorOverlay from '@/components/atoms/ColorOverlay';
import { capitalize } from '@/utils/stringUtils';
import { NO_OF_WELLNESS_DOMAINS } from '@/constants';
import { OUTER_CIRCLE_SIZE } from '@/constants/dimensions';
import colors from '@/theme/colors';
import CircleFillOutWithText from './CircleFillOutWithText';
import WellnessStateCircleChunk from './WellnessStateCircleChunk';

const AVATAR_SIZE = 180;

const ID_COMBINED_WELLNESS_SCORE = 'combined-wellness-score';

const WellnessStateCircle = ({
  wellnessData,
  userAvatar,
}: {
  wellnessData: THealthDomainScores;
  userAvatar?: string;
}) => {
  const wellnessDataSorted = useMemo(() => {
    const wellnessDataArray = Object.entries(wellnessData).map(([wellnessDomain, wellnessValue]) => ({
      domainName: capitalize(wellnessDomain),
      value: wellnessValue,
    }));

    wellnessDataArray.sort((a, b) => (a.value > b.value ? 1 : -1));
    return wellnessDataArray;
  }, [wellnessData]);

  return (
    <Center width={'100%'} position={'relative'} flexDir={'column'}>
      <DrawnCirclesBackground>
        <Center position={'absolute'} width={'100%'} height={'100%'}>
          <Flex height={AVATAR_SIZE} width={AVATAR_SIZE} borderRadius={'50%'} position={'relative'}>
            <ColorOverlay
              color1={colors.extra.black}
              color1Alpha={0.33}
              color2Alpha={0.33}
              style={{ borderRadius: '50%' }}
            />
            <Image
              alt="user-avatar-wellness"
              height={AVATAR_SIZE}
              width={AVATAR_SIZE}
              src={userAvatar ?? '/svg/generic-user.svg'}
              style={{ borderRadius: '50%', objectFit: 'cover' }}
            />
          </Flex>
          <Flex position={'absolute'}>
            <CircleFillOutWithText
              circleDiameter={AVATAR_SIZE}
              svgId={ID_COMBINED_WELLNESS_SCORE}
              thickness={10}
              percentageFilled={Math.floor(
                Object.values(wellnessData).reduce((acc, next) => (acc += next), 0) / NO_OF_WELLNESS_DOMAINS,
              )}
              percentageTextStyle={{
                color: '#FFF',
                fontWeight: 700,
                fontSize: 30,
              }}
              centeredText={'Combined Wellness Score'}
              backgroundTransparent
            />
          </Flex>
        </Center>
        <Center position={'absolute'} width={'100%'} top={5}>
          <WellnessStateCircleChunk
            title={wellnessDataSorted[0].domainName} // "Mental"
            svgId={`${wellnessDataSorted[0].domainName}-svg`}
            percentageFilled={wellnessDataSorted[0].value} // {wellnessData.mental ?? 0}
          />
        </Center>
        <Center
          position={'absolute'}
          width={OUTER_CIRCLE_SIZE - 10}
          top={OUTER_CIRCLE_SIZE / 4.6}
          justifyContent={'space-between'}
        >
          <WellnessStateCircleChunk
            title={wellnessDataSorted[6].domainName}
            svgId={`${wellnessDataSorted[6].domainName}-svg`}
            percentageFilled={wellnessDataSorted[6].value}
            reversed
          />
          <WellnessStateCircleChunk
            title={wellnessDataSorted[1].domainName}
            svgId={`${wellnessDataSorted[1].domainName}-svg`}
            percentageFilled={wellnessDataSorted[1].value}
          />
        </Center>
        <Center
          position={'absolute'}
          width={OUTER_CIRCLE_SIZE + 50}
          marginLeft={'15px'}
          top={OUTER_CIRCLE_SIZE / 2.2}
          justifyContent={'space-between'}
          paddingLeft={'10px'}
        >
          <WellnessStateCircleChunk
            title={wellnessDataSorted[5].domainName}
            svgId={`${wellnessDataSorted[5].domainName}-svg`}
            percentageFilled={wellnessDataSorted[5].value}
            reversed
          />
          <WellnessStateCircleChunk
            title={wellnessDataSorted[2].domainName}
            svgId={`${wellnessDataSorted[2].domainName}-svg`}
            percentageFilled={wellnessDataSorted[2].value}
          />
        </Center>
        <Center
          position={'absolute'}
          width={OUTER_CIRCLE_SIZE / 1.4}
          top={OUTER_CIRCLE_SIZE - 280}
          justifyContent={'space-between'}
          paddingLeft={'10px'}
        >
          <WellnessStateCircleChunk
            title={wellnessDataSorted[4].domainName}
            svgId={`${wellnessDataSorted[4].domainName}-svg`}
            percentageFilled={wellnessDataSorted[4].value}
            reversed
          />
          <WellnessStateCircleChunk
            title={wellnessDataSorted[3].domainName}
            svgId={`${wellnessDataSorted[3].domainName}-svg`}
            percentageFilled={wellnessDataSorted[3].value}
          />
        </Center>
      </DrawnCirclesBackground>
    </Center>
  );
};

export default WellnessStateCircle;
