import logoVariants from '@/assets/img/logo'
import illustrations from '@/assets/img/png/ilustrations'
import { Flex, Image, Spinner, Text, VStack } from '@chakra-ui/react'
import React from 'react'


const LoadingPage = () => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      flexDirection="column"
      alignItems={'center'}
      justifyContent={'center'}
      top={0}
      bg='#4E1AEF'
    >
      <Flex
        width="100vw"
        height="100vh"
        flexDirection="column"
        alignItems={'center'}
        justifyContent={'center'}
        backgroundImage={illustrations.radientOrangeBg}
        backgroundSize={'cover'}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
      >
        <VStack spacing={'40px'}>
          <Image src={logoVariants.white252x80} width={'252px'} height={'80px'} alt='my-omnia-logo' />
          <Text color={'white'} variant={'loraTitle'}>A New Day For Wholeness</Text>
          <Spinner color='white' />
        </VStack>
      </Flex>
    </Flex>
  )
}

export default LoadingPage