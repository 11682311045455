import { useState } from "react";

// import ValueMark from "./parts/ValueMark";
import {
  Image,
  Slider as ChakraSlider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import iconsPng from "@/assets/img/png/icons";
import invertedSliderColorPalette from "./colors/inverted";
import sliderColorPalette from "./colors/standard";

type Props = {
  setValue: (_: number) => void;
  value?: number;
  defaultValue?: number;
  sliderValues?: Array<number>;
  step?: number;
  min?: number;
  max?: number;
  inverted?: boolean;
};

const Slider = ({
  setValue,
  defaultValue = 50,
  value = 50,
  step = 10,
  min = 0,
  max = 100,
  // sliderValues = [0, 25, 50, 75, 100],
  inverted = false,
}: Props) => {
  const [localValue, setLocalValue] = useState(value);
  const [isValueChanged, setIsValueChanged] = useState(false);

  return (
    <ChakraSlider
      step={step}
      min={min}
      max={max}
      defaultValue={defaultValue}
      transformOrigin={'center'}
      focusThumbOnChange={false}
      onChange={(val) => {
        setLocalValue(val);
        setIsValueChanged(true);
      }}
      onChangeEnd={setValue}
      height={"20px"} // to make it easier to click-choose a value
      width={"97%"} // squish the Slider a bit on the sides, to help center the dot at the values "Less" and "More"
      marginTop={"8px"}
    >
      <SliderTrack
        height={"3px"}
        bgGradient={
          inverted
            ? "linear(90deg, #00B547,#A2E2C4,#EC4D84)"
            : "linear(90deg, #EC4D84,#A2E2C4,#00B547)"
        }
      >
        <SliderFilledTrack backgroundColor={"transparent"} />
      </SliderTrack>

      <SliderThumb
        backgroundColor={inverted ? invertedSliderColorPalette[localValue] : sliderColorPalette[localValue]}
        borderColor={isValueChanged ? "white" : "#CCDEEF"}
        boxSize="30px"
        borderWidth={isValueChanged ? "3px" : "1px"}
      >
        {!isValueChanged && <Image
          width={30}
          height={30}
          alt="slider-circle"
          src={iconsPng.sliderCircleUntouched}
        />}
      </SliderThumb>
    </ChakraSlider>
  );
};

export default Slider;
