import { Button, HStack, Progress, Text, VStack } from '@chakra-ui/react';
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import useEmblaCarousel from "embla-carousel-react";
import useAssessmentResult from '@/hooks/assessments/useAssessmentResult';

type Props = {
  title: string,
  subTitle: string,
}

const DocumentSliderForm = ({ title, subTitle, children }: PropsWithChildren<Props>) => {
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { isPending, mutate } = useAssessmentResult()

  const { getValues } = methods;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const progress = (currentSlide/totalSlides) * 100;

  const isLastSlide = currentSlide === totalSlides - 1;
  

  const [emblaRef, emblaApi] = useEmblaCarousel({
    startIndex: 0,
    watchDrag: () => { }, // disables dragging
  });

  const onContinue = () => {
    const canGoNext = emblaApi?.canScrollNext();

    if (canGoNext) {
      emblaApi?.scrollNext();
      setCurrentSlide((prev) => prev + 1);

      window.scrollTo({ top: 100, behavior: 'smooth' });

      return
    }

    const formData = getValues();

    mutate({ formValues: formData });
  }

  useEffect(() => {
    if(emblaApi && totalSlides === 0){
      setTotalSlides(emblaApi.slideNodes().length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi])

  
  return (
    <FormProvider {...methods}>
      <form>
        <VStack>
          <VStack maxWidth={{
            md: '820px'
          }} width={'100%'}>
            <Progress colorScheme='primary' height={'6px'} value={progress} width={'100%'} borderRadius={'20px'} backgroundColor={'rgba(78, 26, 239, 0.075)'} />
            <Text variant={"lora"} textAlign={"center"} fontSize={{ md: '26px' }} fontStyle={'italic'} lineHeight={'32px'} color={'text.darkBlue'} marginTop={'34px'}>
              {title}
            </Text>
            <Text textAlign={'center'} fontSize={'14px'} lineHeight={'16px'} color='text.mediumGray' marginTop={'24px'}>
              {subTitle}
            </Text>
            {(title || subTitle) && (
              <HStack
                height={"1px"}
                maxWidth={"414px"}
                width={"100%"}
                bg="background.blueGray"
                marginY={"30px"}
                borderRadius={"full"}
                opacity={0.3}
              />
            )}
          </VStack>
        </VStack>
        <section className='embla'>
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {children}
            </div>
          </div>
        </section>
      </form>
      <VStack marginTop={'20px'} marginBottom={'40px'}>
        <Button variant={isLastSlide ? 'formSubmit' : 'continue'} onClick={onContinue} isLoading={isPending}>
          {isLastSlide ? 'Finish Screener' : 'Continue'}
        </Button>
      </VStack>
    </FormProvider>
  )
}

export default DocumentSliderForm