import React from 'react'
import { Flex, Image, Spinner, VStack } from '@chakra-ui/react'
import logoVariants from '@/assets/img/logo'

const LoadingState = () => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      flexDirection="column"
      alignItems={'center'}
      justifyContent={'center'}
      top={0}
    >
      <VStack spacing={'40px'}>
        <Image src={logoVariants.purple252x80} width={'252px'} height={'80px'} alt='my-omnia-logo' />
        <Spinner color='white' />
      </VStack>    
    </Flex>
  )
}

export default LoadingState