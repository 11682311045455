type ColorObject = {
  [key: number]: string;
};

const invertedSliderColorPalette: ColorObject = {
  0: "#00b547",
  1: "#01ac50",
  2: "#03ab50",
  3: "#06aa51",
  4: "#08a951",
  5: "#0aa852",
  6: "#0da752",
  7: "#0fa653",
  8: "#12a554",
  9: "#14a454",
  10: "#16a355",
  11: "#0b8f1b",
  12: "#109423",
  13: "#14992b",
  14: "#189f33",
  15: "#1da43b",
  16: "#21a943",
  17: "#26ae4b",
  18: "#2ab353",
  19: "#2fb95b",
  20: "#33be63",
  21: "#1ba334",
  22: "#21a73c",
  23: "#27ab44",
  24: "#2db04c",
  25: "#33b454",
  26: "#38b85c",
  27: "#3ebc64",
  28: "#44c06c",
  29: "#4ac474",
  30: "#4fc77c",
  31: "#44bd5e",
  32: "#48bf64",
  33: "#4cc16a",
  34: "#50c370",
  35: "#54c575",
  36: "#58c77b",
  37: "#5cc981",
  38: "#60cc87",
  39: "#64ce8d",
  40: "#68d093",
  41: "#5fcf80",
  42: "#63d084",
  43: "#67d189",
  44: "#6bd28e",
  45: "#6fd392",
  46: "#72d497",
  47: "#76d59c",
  48: "#7ad6a0",
  49: "#7ed7a5",
  50: "#81d8a9",
  51: "#6bd3b7",
  52: "#71d3b3",
  53: "#77d2ae",
  54: "#7dd2aa",
  55: "#83d1a5",
  56: "#89d1a1",
  57: "#8fd09d",
  58: "#95d098",
  59: "#9bcf94",
  60: "#a2cf8f",
  61: "#84f09d",
  62: "#8cea97",
  63: "#93e590",
  64: "#9bde89",
  65: "#a2d683",
  66: "#aacf7c",
  67: "#b1c775",
  68: "#b9c06e",
  69: "#c0b968",
  70: "#c8b161",
  71: "#aad781",
  72: "#afdc7c",
  73: "#b5cc77",
  74: "#bbc171",
  75: "#c1c66c",
  76: "#c7bc67",
  77: "#cdb262",
  78: "#d4a75c",
  79: "#da9d57",
  80: "#e09352",
  81: "#dbcb56",
  82: "#ddc058",
  83: "#dfb659",
  84: "#e1ac5a",
  85: "#e3a25b",
  86: "#e6985c",
  87: "#e88d5d",
  88: "#ea835f",
  89: "#ec7960",
  90: "#ef6f61",
  91: "#ef6d65",
  92: "#ef6a69",
  93: "#ee666c",
  94: "#ee6370",
  95: "#ee5f73",
  96: "#ed5b76",
  97: "#ed577a",
  98: "#ed547d",
  99: "#ec5080",
  100: "#EC4D84",
};

export default invertedSliderColorPalette;